<template>
  <div class="Name">
    <div class="nameSection">
      <!-- <div class="label">Name</div> -->
      <div class="name">
        {{ name.value }}
      </div>
    </div>
    <div class="codeSection">
      <!-- <div class="label">Code</div> -->
      <div
        v-if="code"
        class="code">
        {{ code.code }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    inject: ['module'],
    props: {
        nameId: {
            type: String,
            required: true
        }
    },
    computed: {
        name() {
            return this.$store.getters['moduleSystemName/getNameById'](this.nameId); // || { value: 'missing' }
        },
        code() {
            // return 'the code'
            let code = null;
            if (this.name.codes) {
                code = this.$store.getters['moduleSystemName/getCodeById'](this.name.codes[0]);
            }
            return code;
        }
    }
};
</script>

<style scoped lang="scss">
.Name {
    border-radius: 0.5em;
    overflow: hidden;
    padding: 1.5em 2em;
    background-color: white;
    color: mix(black, white, 80%);
    display: flex;
    align-items: center;
}
.name {
    flex: 1;
}

.label {
    color: mix(black, white, 40%);
    font-size: 90%;
    margin-bottom: 0.3em;
}

.code {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.nameSection,
.codeSection {
    flex: 1;
    overflow: hidden;
}
// .Name {
//   border-radius: 0.5em;
//   overflow: hidden;
//   padding: 2em 1em;
//   background-color: white;
//   color: mix(black, white, 80%);
//   display: flex;
//   align-items: center;
// }
// .name {
//   flex: 1;
// }
// .viewLabel {
//   color: rgba(black, 0.2);
//   font-size: 80%;
// }
</style>
