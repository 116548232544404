<template>
  <div
    class="CategoryView"
    :class="{ showingNameDetail: selectedNameId }">
    <div
      class="header"
      :style="style">
      <div class="top">
        <div
          class="--global-clickable"
          @click="back()">
          <svgicon
            class="backIcon"
            icon="arrow-left" />
        </div>
        <div class="place">
          {{ $t(`country.${category.place}`) }}
        </div>
        <div class="topEdge" />
      </div>
    </div>

    <div class="body">
      <div class="infoSection">
        <div
          class="icon"
          :style="{ backgroundColor: category.color }">
          <svgicon
            class="iconSvg"
            :icon="category.type" />
        </div>
        <div class="tag">
          {{ $t('top100') }}
        </div>
        <div class="title">
          {{ $t(`${category.type}`) }}
        </div>
      </div>

      <div class="tabs">
        <div
          class="Tab --global-clickable"
          :class="{ active: tabIndex === 0 }"
          :style="{ borderColor: theme.primaryColor }"
          @click="setTabIndex(0)">
          <span class="Tab__label">{{ $t('learn') }}</span>
        </div>
        <div
          class="Tab --global-clickable"
          :class="{ active: tabIndex === 1 }"
          :style="{ borderColor: theme.primaryColor }"
          @click="setTabIndex(1)">
          <span class="Tab__label">{{ $t('remember') }}</span>
          <span
            v-if="dueCount"
            class="dueCount">
            <span class="dueCountNumber">{{ dueCount }}</span>
          </span>
        </div>
      </div>
      <div
        class="items"
        :data-filter="['learn', 'remember'][tabIndex]">
        <Name
          v-for="item of items"
          :key="item.nameId"
          class="--global-clickable"
          :name-id="item.nameId"
          :class="{ didLearn: item.didLearn, hasRep: item.repLevel }"
          @click.native="showNameDetail(item.nameId)" />
        <div
          v-if="tabIndex === 1 && xxxCount === 0"
          class="emptyMessage">
          {{ $t('empty') }}
        </div>
      </div>
    </div>

    <div class="footer">
      <div
        class="button --global-clickable"
        :class="{ enabled: canStartSession }"
        :style="{ backgroundColor: theme.primaryColor }"
        @click="learnNew()">
        {{ $t('start') }}
      </div>
    </div>

    <div
      v-if="selectedNameId"
      class="nameDetailLayer"
      @click="showNameDetail(null)">
      <div class="spacer" />
      <NameWithCodesCard :name-id="selectedNameId" />
      <div class="spacer" />
      <div class="nameDetailLayerCloseButton --global-clickable">
        <svgicon
          class="closeIcon"
          icon="close" />
      </div>
    </div>
  </div>
</template>

<translations>
  top100: 'Top 100'
  top100_no: 'Topp 100'
  male: 'Male names'
  male_no: 'Mannsnavn'
  female: 'Female names'
  female_no: 'Kvinnenavn'
  surnames: 'Surnames'
  surnames_no: 'Etternavn'
  learn: 'Learn fast'
  learn_no: 'Lær hurtig'
  remember: 'Remember forever'
  remember_no: 'Husk for alltid'
  empty: 'Empty'
  empty_no: 'Tom'
  start: 'Start'
  start_no: 'Start'
</translations>
<script>
import Name from '../components/Name';
import NameWithCodesCard from '../components/NameWithCodesCard';

export default {
    components: { Name, NameWithCodesCard },
    inject: ['module', 'theme'],
    data() {
        return {
            tabIndex: 0,
            selectedNameId: null
        };
    },
    computed: {
        style() {
            return {
                backgroundImage: this.theme.coverImage ? `url(${this.theme.coverImage})` : null
            };
        },
        categoryId() {
            return this.$route.params.categoryId;
        },
        category() {
            return this.module.state.categories[this.categoryId];
        },
        duePersons() {
            return this.module.getters('duePersonsInCategory')(this.categoryId);
        },
        items() {
            return this.category.nameIds.map(nameId => {
                const nameUserstate = this.module.state.namesUserstate[nameId];
                return {
                    nameId,
                    repLevel: nameUserstate ? nameUserstate.repLevel : 0
                };
            });
        },
        xxxCount() {
            return this.items.filter(item => item.repLevel).length;
        },
        dueCount() {
            return this.items.filter(item => item.repLevel).length;
        },
        canStartSession() {
            return (this.tabIndex === 0 && this.items.length - this.xxxCount) || (this.tabIndex === 1 && this.xxxCount);
        }
    },
    methods: {
        setTabIndex(index) {
            this.tabIndex = index;
        },
        back() {
            this.$router.push({ name: 'NameSystemHomeView' });
        },
        learnNew() {
            if (!this.canStartSession) {
                return;
            }

            const session = {};
            if (this.tabIndex === 0) {
                session.type = 'normal';
                session.nameIds = this.items
                    .filter(x => !x.repLevel)
                    .slice(0, 10)
                    .map(x => x.nameId);
            } else {
                session.type = 'repetition';
                session.nameIds = this.items
                    .filter(x => x.repLevel)
                    .slice(0, 10)
                    .map(x => x.nameId);
            }

            const persons = session.nameIds.map(nameId => {
                const name = this.module.state.names[nameId];
                return {
                    name: nameId, // just now
                    // random image
                    gender: name.gender
                };
            });
            session.persons = persons;

            this.module.dispatch('setSession', session);
            this.$router.push({ name: 'NameSystemSessionView', params: { categoryId: this.categoryId } });
        },
        showNameDetail(nameId) {
            this.selectedNameId = nameId;
        }
    }
};
</script>

<style scoped lang="scss">
.CategoryView {
    display: flex;
    flex-direction: column;

    // height: 100%;

    &.showingNameDetail {
        & > * {
            // opacity: 0.1;
            visibility: hidden;
        }
        .header {
            visibility: visible;
            opacity: 0.1;
        }
    }
}

.scrollContainer {
    flex: 1;
    overflow-y: auto;
}
.spacer {
    flex: 1;
}
.header {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    .infoSection {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding-bottom: 2em;
    }
    .tag {
        color: rgba(white, 0.5);
        margin-top: 2em;
        margin-bottom: 0.3em;
    }
    .title {
        color: rgba(white, 0.8);
        font-size: 140%;
    }
    .top {
        height: $names-headerHeight;
        align-self: stretch;
        display: flex;
        align-items: center;
        padding: 0em 2em;

        background-color: rgba(black, 0.1);
    }
    .topEdge {
        width: 1em;
    }
    .backIcon {
        $size: 1em;
        width: $size;
        height: $size;
        fill: white;
    }

    .icon {
        $size: 5em;
        width: $size;
        height: $size;
        background-color: rgba(white, 0.1);
        border-radius: 0.3em;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 2em;
    }
    .iconSvg {
        $size: 2em;
        width: $size;
        height: $size;
        fill: white;
    }
    .place {
        flex: 1;
        text-align: center;
        color: rgba(white, 0.8);
    }
    .body {
        padding: 2em;
        padding-top: 0;
        flex: 1;
        overflow-y: auto;
    }
    .Name {
        &:not(:first-child) {
            border-top: 1px solid rgba(black, 0.05);
        }
    }

    .items {
        background-color: white;
        padding: 0 1em;
        border-radius: 1em;
        margin-top: 2em;

        flex: 1;
        overflow: hidden;

        &[data-filter='learn'] {
            .Name.hasRep {
                display: none;
            }
        }
        &[data-filter='remember'] {
            .Name:not(.hasRep) {
                display: none;
            }
        }
    }

    .emptyMessage {
        color: black;
        padding: 1em;
    }

    .footer {
        display: flex;
        justify-content: center;
        padding: 2em 0;
    }
    .button {
        $size: 4em;
        width: $size;
        height: $size;
        color: white;
        border-radius: 4em;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(.enabled) {
            pointer-events: none;
            opacity: 0.1;
        }
    }
    .button--review {
        margin-top: 1em;
    }

    .tabs {
        display: flex;
        justify-content: center;
    }
    .Tab {
        border-bottom: 2px solid transparent;
        padding-bottom: 0.5em;
        &:not(.active) {
            border-color: transparent !important;
            color: rgba(white, 0.5);
        }
        &:not(:first-child) {
            margin-left: 1em;
        }
    }
    .Tab__label {
        text-transform: uppercase;
        font-size: 80%;
    }
    .dueCount {
        $size: 0.8em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: $size;
        height: $size;
        background-color: red;
        border-radius: 50%;
        position: relative;
        top: -0.5em;
    }
    .dueCountNumber {
        color: white;
        font-size: 60%;
    }

    .nameDetailLayer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1 !important;
        visibility: visible !important;

        padding: 2em;
        padding-bottom: 2em;

        display: flex;
        flex-direction: column;
        // justify-content: center;
    }
    .nameDetailLayerCloseButton {
        $size: 3.5em;
        width: $size;
        height: $size;
        border: 1px solid rgba(white, 0.3);
        border-radius: 50%;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .closeIcon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: rgba(white, 0.8);
    }

    .NameWithCodesCard {
        min-height: 20em;
    }
}
</style>
